import Banner from './Banner';

function InfoBanner() {
  return (
    <Banner
      title="Potrzebujesz wyceny lub informacji na temat barier?"
      description="Jeśli szukasz szczegółowych informacji na temat naszych barier lub potrzebujesz indywidualnej wyceny, nasz zespół jest gotowy, aby odpowiedzieć na wszystkie Twoje pytania. Zapewniamy profesjonalne doradztwo, dopasowane do Twoich konkretnych wymagań i oczekiwań, abyś mógł wybrać najlepsze rozwiązanie bezpieczeństwa drogowego. Skontaktuj się z nami już dziś!"
      imageUrl="/info-baner.webp"
      contactButtonTitle="Napisz do nas"
      contactButtonOnClick={() =>
        document
          .getElementById('contact')!
          .scrollIntoView({ behavior: 'smooth' })
      }
      titleClass="!text-[30px] !xl:text-[40px] !2xl:text-[50px]"
      parentClass="py-8"
    />
  );
}

export default InfoBanner;
