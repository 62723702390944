import Button from './Button';

function TextWithBaner(props: {
  title: string;
  desc?: string;
  imageUrl: string;
  children?: JSX.Element;
}) {
  return (
    <div className={`flex ${props.children ? 'flex-row-reverse' : 'flex-row'}`}>
      <div
        className={`flex flex-col gap-4 justify-center items-center z-10 ${
          props.children ? 'w-full lg:w-[45%]' : 'w-full lg:w-[35%]'
        }`}
      >
        <div
          className={`font-extrabold w-full text-[30px] xl:text-[40px] 2xl:text-[50px] leading-tight text-white bg-secondary p-8 ${
            props.children ? 'lg:pl-16' : 'lg:pr-12'
          }`}
        >
          {props.title}
        </div>
        {props.children ? (
          props.children
        ) : (
          <div className="flex flex-col gap-4 justify-start items-start">
            <div className="pr-8">{props.desc}</div>
            <Button
              onClick={() =>
                document
                  .getElementById('contact')!
                  .scrollIntoView({ behavior: 'smooth' })
              }
              label="Napisz do nas"
            />
          </div>
        )}
      </div>
      <img
        src={props.imageUrl}
        className={`hidden lg:block ${
          props.children ? 'w-[55%]' : 'w-[65%]'
        } z-20`}
        alt="banner image"
        loading="lazy"
      />
    </div>
  );
}

export default TextWithBaner;
