import createStyleForBackgroundImage from '@/helper/createStyleForBackgroundImage';
import Button from './Button';
import { useState } from 'react';
import ContactForm from './form/ContactForm';

function Banner(props: {
  title: string;
  description?: string;
  contactButtonModal?: boolean;
  contactButtonTitle?: string;
  contactButtonOnClick?: () => void;
  whatsappButton?: boolean;
  imageUrl: string;
  parentClass?: string;
  cardClass?: string;
  titleClass?: string;
  bigCard?: boolean;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div
        className={`h-full w-full flex flex-col ${
          props.bigCard
            ? 'justify-start items-start'
            : 'justify-center items-center text-center'
        } ${props.parentClass}`}
        style={createStyleForBackgroundImage(props.imageUrl)}
      >
        <div
          className={`${
            props.bigCard
              ? 'w-full h-full lg:w-[50%] items-center lg:items-end justify-center px-4 lg:px-12'
              : 'w-[60%] lg:w-[35%] p-8'
          } backdrop-card flex flex-col gap-4 ${props.cardClass}`}
        >
          <div
            className={`font-extrabold text-[30px] lg:text-[40px] xl:text-[50px] 2xl:text-[60px] leading-tight text-default ${
              props.bigCard && 'max-w-[80%] md:max-w-[65%]'
            } ${props.titleClass}`}
          >
            {props.title}
          </div>
          {props.description && (
            <div
              className={`text-default ${
                props.bigCard && 'max-w-[80%] md:max-w-[65%]'
              }`}
            >
              {props.description}
            </div>
          )}
          {props.contactButtonTitle && (
            <div className="flex flex-col justify-center items-center gap-4">
              <Button
                label={props.contactButtonTitle}
                onClick={() => {
                  if (props.contactButtonModal) {
                    setIsModalOpen(true);
                    return;
                  } else {
                    props.contactButtonOnClick?.();
                  }
                }}
              />
              {props.whatsappButton && (
                <div className="flex flex-row gap-4">
                  <div className="text-default">Lub napisz do nas</div>
                  <div
                    className="h-6 w-6 cursor-pointer fill-white hover:fill-secondary transition-all duration-300"
                    onClick={() => (window.location.href = 'tel:+48533809470')}
                  >
                    <svg
                      width="31"
                      height="31"
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                      className="fill-inherit"
                    >
                      <path
                        className="fill-inherit"
                        d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm5.425781 405.050781c-.003906 0 .003907 0 0 0h-.0625c-25.644531-.011719-50.84375-6.441406-73.222656-18.644531l-81.222656 21.300781 21.738281-79.375c-13.410156-23.226562-20.464844-49.578125-20.453125-76.574219.035156-84.453124 68.769531-153.160156 153.222656-153.160156 40.984375.015625 79.457031 15.96875 108.382813 44.917969 28.929687 28.953125 44.851562 67.4375 44.835937 108.363281-.035156 84.457032-68.777343 153.171875-153.21875 153.171875zm0 0"
                      />
                      <path d="m261.476562 124.46875c-70.246093 0-127.375 57.105469-127.40625 127.300781-.007812 24.054688 6.726563 47.480469 19.472657 67.75l3.027343 4.816407-12.867187 46.980468 48.199219-12.640625 4.652344 2.757813c19.550781 11.601562 41.964843 17.738281 64.816406 17.746094h.050781c70.191406 0 127.320313-57.109376 127.351563-127.308594.011718-34.019532-13.222657-66.003906-37.265626-90.066406-24.042968-24.0625-56.019531-37.324219-90.03125-37.335938zm74.90625 182.035156c-3.191406 8.9375-18.484374 17.097656-25.839843 18.199219-6.597657.984375-14.941407 1.394531-24.113281-1.515625-5.5625-1.765625-12.691407-4.121094-21.828126-8.0625-38.402343-16.578125-63.484374-55.234375-65.398437-57.789062-1.914063-2.554688-15.632813-20.753907-15.632813-39.59375 0-18.835938 9.890626-28.097657 13.398438-31.925782 3.511719-3.832031 7.660156-4.789062 10.210938-4.789062 2.550781 0 5.105468.023437 7.335937.132812 2.351563.117188 5.507813-.894531 8.613281 6.570313 3.191406 7.664062 10.847656 26.5 11.804688 28.414062.957031 1.917969 1.59375 4.152344.320312 6.707031-1.277344 2.554688-5.519531 8.066407-9.570312 13.089844-1.699219 2.105469-3.914063 3.980469-1.679688 7.8125 2.230469 3.828125 9.917969 16.363282 21.296875 26.511719 14.625 13.039063 26.960938 17.078125 30.789063 18.996094 3.824218 1.914062 6.058594 1.59375 8.292968-.957031 2.230469-2.554688 9.570313-11.175782 12.121094-15.007813 2.550782-3.832031 5.105469-3.191406 8.613282-1.914063 3.511718 1.273438 22.332031 10.535157 26.160156 12.449219 3.828125 1.917969 6.378906 2.875 7.335937 4.472657.960938 1.597656.960938 9.257812-2.230469 18.199218zm0 0" />
                    </svg>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {isModalOpen && (
        <div
          id="modal-root"
          className="fixed left-0 top-0 w-screen h-screen flex flex-col justify-center items-center backdrop-card z-50"
        >
          <div className="w-[90%] h-[95%] lg:h-[80%] lg:w-[80%] bg-[#FFFFFF86] overflow-hidden flex flex-col justify-center items-center shadow-xl rounded-[30px]">
            <div className="w-full h-full relative flex flex-col xl:flex-row gap-1 xl:gap-12 justify-center items-center">
              <div
                className="text-[22px] text-black hover:text-secondary flex flex-row justify-center items-start gap-4 transition-all duration-300 absolute top-4 right-10 cursor-pointer"
                onClick={() => setIsModalOpen(false)}
              >
                <div className="font-bold text-[20px] text-inherit">Zwiń</div>
                <div className="text-inherit">✖</div>
              </div>
              <div className="flex flex-col gap-1 xl:gap-8 xl:w-[40%] px-4 xl:pl-8">
                <div className="text-[18px] xl:text-[45px] font-bold text-defaultDark">
                  Potrzebujesz wyceny lub informacji na temat barier?
                </div>
                <div className="text-[14px] xl:text-[16px] text-defaultDark">
                  Binnar proponuje szeroki zakres usług, który w głównej mierze
                  opiera się na wsparciu i realizacji inwestycji drogowych i
                  budowlanych. Od kilku lat obsłużyliśmy setki udanychrealizacji
                  i zbudowaliśmy trwałe relacje z naszymi partnerami biznesowymi
                </div>
              </div>
              <div className="xl:w-[60%] px-8 xl:px-0">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Banner;
