import { defaultStyles } from '@/helper/defaultStyles';
import InfoBanner from './InfoBanner';

function InfoBannerSection() {
  return (
    <section className={`${defaultStyles.paddingBig} py-12`}>
      <InfoBanner />
    </section>
  );
}

export default InfoBannerSection;
