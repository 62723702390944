function TitleBlockWrapper(props: {
  title: string;
  desc?: string;
  children: JSX.Element;
  parentClass?: string;
  titleClass?: string;
  descClass?: string;
}) {
  return (
    <div className={`flex flex-col gap-8 ${props.parentClass}`}>
      <div className="flex flex-col gap-4 lg:gap-12 justify-center items-center text-center">
        <div
          className={`font-bold text-[40px] md:text-[50px] leading-tight lg:w-[80%] text-defaultDark ${props.titleClass}`}
        >
          {props.title}
        </div>
        {props.desc && (
          <div className={`text-defaultDark lg:w-[80%] ${props.descClass}`}>
            {props.desc}
          </div>
        )}
      </div>
      {props.children}
    </div>
  );
}

export default TitleBlockWrapper;
