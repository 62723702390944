function CarouselArrow(props: {
  isLeft?: boolean;
  onClick?: (e: any) => void;
  class?: string;
}) {
  const mirrorStyle = props.isLeft ? {} : { transform: 'scaleX(-1)' };

  return (
    <div
      className={`stroke-default hover:stroke-secondary transition-all duration-300 w-12 h-12 cursor-pointer ${props.class}`}
    >
      <svg
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          ...mirrorStyle,
          transition: 'background-color 0.3s, color 0.3s',
        }}
        className="stroke-inherit"
        onClick={props.onClick}
      >
        <path
          d="M21 13.9995L14 20.9995L21 27.9995"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="stroke-inherit"
        />
        <path
          d="M27.998 21L13.998 21"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="stroke-inherit"
        />
      </svg>
    </div>
  );
}

export default CarouselArrow;
